import './App.css';
import {BrowserRouter as Router} from 'react-router-dom'
import Reportcontent from './components/Reportcontent';
import Descriptor from './components/Descriptor';


function App() {
  return (
<Router>
    <div className=' block max-w-none pt-[30px] pr-[30px] pb-[40px] pl-[30px] relative w-full mt-0 mr-auto mb-0 ml-auto sm:flex sm:flex-col sm:pt-[10px] sm:pr-[10px] sm:pb-[10px] sm:pl-[10px]'>
      <Reportcontent/>
      <Descriptor/>
    </div>
    </Router>
  );
}

export default App;
