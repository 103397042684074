import React from 'react';
import { useState,useEffect } from 'react';
import '../App.css';


function Descriptor({ selectedKeyword,onClose }) {

  const [isClosing, setIsClosing] = useState(false);

  useEffect(() => {
    if (selectedKeyword) {
      setIsClosing(false);
    }
  }, [selectedKeyword]);

  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      onClose();
    }, 300);
  };


  return (
    <div className={`descriptor-container ${selectedKeyword ? 'open' : 'closed'} ${isClosing ? 'closing' : ''}`}>
      {selectedKeyword && (
        <div className='descriptor-wrapper'>

          <div className=' h-auto content-container'>
          <span class="material-symbols-outlined absolute top-[1rem] right-[1rem] cursor-pointer text-[#cbc8c8] opacity-70 "  onClick={handleClose}>
            close
          </span>
            <div className=' block pt-[30px] pr-[30px] pb-[75px] pl-[30px] text-[#edecf6] sm:pb-[35px]'>

              <>
                <h3 className=' text-[1.5em] font-semibold text-[3b3fbe8] leading-[1.25] pb-[0.625em] mb-[0.625em] mt-0 inline-block border-b-[2px] border-border-solid border-b-[#6FF7D333] sm:text-[1.3em]'>
                  {selectedKeyword.keyword}
                </h3>
                <p className=' text-[1.0625em] mb-[1rem] mt-0 mr-0 ml-0 text-[#edecf6] sm:text-[1em]'>
                  {selectedKeyword.explanation}
                </p>
                <div className=' flex  '>
                <img src={selectedKeyword.imageUrl} alt='description' className=' max-h-[400px] sm:max-h-[200px]'></img>
                </div>
              </>

            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Descriptor;
