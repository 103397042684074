import React, { useEffect } from 'react'
import { useState } from 'react'
import { json, useLocation } from 'react-router-dom';
import '../App.css'
import Descriptor from './Descriptor'
import keywords from '../data/keyword.json';
import monitor from '../Assets/monitor.png'
import { tailspin } from 'ldrs'

tailspin.register()

function Reportcontent() {
     const [selectedKeyword, setSelectedKeyword] = useState(null);
     const [pdfUrl, setPdfUrl] = useState('');
     const [jsondata, setJsonData] = useState([]);
     const [isLoading, setIsLoading] = useState(false);
     const location = useLocation();
     const pathnameParts = location.pathname.split("/");
     const study_id = pathnameParts[pathnameParts.length - 1];
     const date = jsondata?.patient_details?.Date;
     const [name, setName] = useState();
     const [orderid, setorderid] = useState();
     const [age, setAge] = useState();
     const [sex, setSex] = useState();
     const backendUrl = process.env.REACT_APP_BACKEND_URL;
     const backendUrlOrderId = process.env.REACT_APP_BACKEND_URL_ORDER_ID;



     const handleCloseDescriptor = () => {
          setSelectedKeyword(null);
     };



     useEffect(() => {
          const fetchData = async () => {
              const storedData = sessionStorage.getItem('reportData');
              const storedAge = sessionStorage.getItem('age');
              const storedSex = sessionStorage.getItem('sex');
              const storedName = sessionStorage.getItem('name');
              const storedOrderId = sessionStorage.getItem('order_id');
      
              if (storedData) {
                  setJsonData(JSON.parse(storedData));
                  if (storedAge) setAge(storedAge);
                  if (storedSex) setSex(storedSex);
                  if (storedName) setName(storedName);
                  if (storedOrderId) setorderid(storedOrderId);
                  if(storedSex) setSex(storedSex === 'M' ? 'Male' : 'Female');
                  if(storedAge) setAge(storedAge);
              } else {
                  setIsLoading(true);
                  fetch(`${backendUrlOrderId}/report/reports-signed-url/${study_id}`)
                      .then(response => response.json())
                      .then(data => {
                          const url = Object.values(data)[0];
                          setPdfUrl(url);
      
                          fetch(`${backendUrl}/extract-pdf/`, {
                              method: 'POST',
                              headers: {
                                  'Content-Type': 'application/json',
                              },
                              body: JSON.stringify({ pdf_url: url }),
                          })
                              .then(response => response.json())
                              .then(data => {
                                  setJsonData(data);
                                  const ageSex = data.patient_details?.["Age/Sex"];
                                      const [age, sex] = ageSex.split(' / ');
                                      setAge(age);
                                      sessionStorage.setItem('age', age);
                                      setSex(sex === 'M' ? 'Male' : 'Female');
                                      sessionStorage.setItem('sex', sex);
                                  sessionStorage.setItem('reportData', JSON.stringify(data));
                              })
                              .catch(error => {
                                  console.error('Error:', error);
                              })
                              .finally(() => {
                                  setIsLoading(false);
                              });
                      })
                      .catch(error => {
                          console.error('Error fetching data:', error);
                      });
              }
          };
          fetchData();
      }, []);




     const generateKneeFindingsRows = () => {
          if (!jsondata || !jsondata.left_knee_findings || !jsondata.right_knee_findings) {
               return null;
          }
          const allKeys = Object.keys({
               ...jsondata.left_knee_findings,
               ...jsondata.right_knee_findings,
          });

          return allKeys.map((key) => (
               <tr key={key} className="bg-white border-b">
                    <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                         {highlightKeywords(key)}
                    </th>
                    <td className="px-6 py-4 text-black">
                         {highlightKeywords(jsondata.left_knee_findings[key])}
                    </td>
                    <td className="px-6 py-4 text-black">
                         {highlightKeywords(jsondata.right_knee_findings[key])}
                    </td>
               </tr>
          ));
     };

     const renderDetailedFindings = () => {
          return jsondata.detailed_findings?.map((finding, index) => (
               <li key={index} className='list-disc mt-[5px] mb-[5px] ml-0 mr-0'>{highlightKeywords(finding)}</li>
          ));
     };

     const renderImpression = () => {
          return jsondata.impressions?.map((finding, index) => (
               <li key={index} className='list-disc mt-[5px] mb-[5px] ml-0 mr-0'>{highlightKeywords(finding)}</li>
          ));
     };

     const highlightKeywords = (text) => {
          const elements = [];
          let remainingText = text;

          for (let i = 0; i < keywords.length; i++) {
               const keywordData = keywords[i];
               const keyword = keywordData.Keyword.toLowerCase();
               let keywordIndex = remainingText.toLowerCase().indexOf(keyword);

               while (keywordIndex !== -1) {
                    if (keywordIndex > 0) {
                         elements.push(<span key={elements.length}>{remainingText.substring(0, keywordIndex)}</span>);
                    }

                    elements.push(
                         <span
                              key={elements.length}
                              className="keyword-animate underline decoration-[2.5px] decoration-[#6FF7D3] bg-[#cefff280] cursor-pointer"
                              onClick={() => handleKeywordClick(keyword)}
                         >
                              {remainingText.substring(keywordIndex, keywordIndex + keyword.length)}
                         </span>
                    );

                    remainingText = remainingText.substring(keywordIndex + keyword.length);

                    keywordIndex = remainingText.toLowerCase().indexOf(keyword);
               }
          }

          if (remainingText.length > 0) {
               elements.push(<span key={elements.length}>{remainingText}</span>);
          }

          return elements;
     };




     const handleKeywordClick = (keyword) => {
          const selectedKeywordData = keywords.find(
               (item) => item.Keyword.toLowerCase() === keyword.toLowerCase()
          );
          if (selectedKeywordData) {
               setSelectedKeyword({
                    keyword: selectedKeywordData.Keyword,
                    explanation: selectedKeywordData.Explanation,
                    imageUrl: selectedKeywordData.image,
               });
          }
     };



     return (
          <>

               {isLoading ? (
                    <div className=' flex items-center justify-center absolute inset-0 h-screen'>
                         <l-tailspin
                              size="40"
                              stroke="5"
                              speed="0.9"
                              color="#5c4bce"
                         ></l-tailspin>
                    </div>
               ) : (
                    <div className=' block relative m-0 report-container sm:w-full'>
                         {!selectedKeyword && (
                              <div className=' highlight-container sm:hidden'>
                                   <div className=' gap-[20px] flex-col-reverse  w-full flex items-center justify-center mt-[30px] mr-0 mb-[40px] ml-0'>
                                        <div className=' max-w-[20em] '>
                                             <p className=' text-[1.5em] font-semibold mb-[0.5em] text-[#5c4bce] text-center leading-[1.5]'>How it works</p>
                                             <p className=' text-[1.2em] text-[#616161] text-center leading-[1.5]'>
                                                  Click on <span className=' underline decoration-[2px] decoration-[#6FF7D3] bg-[#cefff280]'>highlighted</span> words to see the explanation
                                             </p>
                                        </div>
                                        <img src={monitor} alt='icon'></img>

                                   </div>
                              </div>
                         )}
                         <div className=' rounded-[15px] bg-[#ffffff] pt-[42px] pr-[40px] pb-[50px] pl-[40px] report-wrapper sm:pt-[21px] sm:pr-[20px] sm:pb-[25px] sm:pl-[20px] '>
                              <div className='flex justify-between border-b-[2px] border-b-[#e6e6e6] border-dashed mb-[30px] pt-0 pr-0 pl-0 pb-[25px] sm:flex-col'>
                                   <div className=' pb-[0.5em]'>
                                        <div>
                                             <div className=' flex flex-row justify-start w-full'>
                                                  <div className=' head-logo'></div>
                                                  <h2 className=' text-[1.75em] pl-[15px] pr-0 font-semibold subject-heading sm:text-[1.3rem]'>Bone Health Monitor</h2>
                                             </div>
                                             <p className=' pl-[55px] mt-[0.5em] text-[1em] text-[#616161] leading-[1.5] sm:mt-0'>Performed {date}</p>
                                        </div>
                                        {/* <div className=' flex flex-row items-center pt-[0.75em] '>
                                             <img src='https://scanslated.com/sample-report/img/doctor-photos/nicholas-befera_400-50.jpg' className=' w-[40px] h-[40px] mr-[15px] rounded-[50%]'></img>
                                             <div>
                                                  <p className=' text-[1em] text-[#616161] tracking-[0.6px] '>Referred by Dr.Kiran</p>
                                             </div>
                                        </div> */}
                                   </div>
                                   <div className=' sm:grid sm:grid-cols-2 sm:gap-3 justify-center '>
                                        <p>Name:{jsondata.patient_details?.Name}</p>
                                        <p>Sex:{age}</p>
                                        <p>Age:{sex}</p>
                                        <p>Order ID:{jsondata?.patient_details?.["Order ID"]}</p>
                                   </div>

                              </div>
                              <div className=' report-text'>
                                   <div className='table-data-wrapper'>
                                        <h2 className=' mt-auto mb-[1rem] ml-[0.5rem] text-[1.3rem] font-medium'>Summary of Findings:</h2>
                                        <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
                                             <table class="w-full text-sm text-left rtl:text-right text-gray-50">
                                                  <thead class="text-xs text-gray-700 uppercase bg-gray-50 ">
                                                       <tr>
                                                            <th scope="col" class="px-6 py-3">
                                                                 Parameter
                                                            </th>
                                                            <th scope="col" class="px-6 py-3">
                                                                 Left Knee
                                                            </th>
                                                            <th scope="col" class="px-6 py-3">
                                                                 Right Knee
                                                            </th>
                                                       </tr>
                                                  </thead>
                                                  <tbody>

                                                       {generateKneeFindingsRows()}

                                                  </tbody>
                                             </table>
                                        </div>
                                   </div>
                                   <div className=' observation-container mt-[2rem]'>
                                        <div className='observation-title'>
                                             <h2 className=' mt-auto mb-[1rem] ml-[0.5rem] text-[1.3rem] font-medium'>Observation:</h2>
                                        </div>
                                        <div className=' observation-content'>
                                             <ul className=' mt-0 mb-0 ml-[2rem] mr-[2rem]'>
                                                  {renderDetailedFindings()}

                                             </ul>
                                        </div>
                                   </div>
                                   <div className=' observation-container mt-[2rem]'>
                                        <div className='observation-title'>
                                             <h2 className=' mt-auto mb-[1rem] ml-[0.5rem] text-[1.3rem] font-medium'>impression</h2>
                                        </div>
                                        <div className=' observation-content'>
                                             <ul className=' mt-0 mb-0 ml-[2rem] mr-[2rem]'>
                                                  {renderImpression()}
                                             </ul>
                                        </div>
                                   </div>
                                   {/* {selectedKeyword && <div className='report-arrow' style={{ top: arrowPosition.top }}></div>}        */}
                              </div>
                         </div>
                    </div>
               )}
               <Descriptor selectedKeyword={selectedKeyword} onClose={handleCloseDescriptor} />

          </>
     )
}

export default Reportcontent